import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ItemPreview from '../components/item-preview'

class ItemIndex extends React.Component {
  render() {
	const posts = get(this, 'props.data.allContentfulItem.nodes')
    return (
      <Layout location={this.props.location}>
        <Seo title="Item" />
        <Hero title="Item" />
        <ItemPreview posts={posts} />
      </Layout>
    )
  }
}

export default ItemIndex


export const pageQuery = graphql`
  query ItemIndexQuery {
    allContentfulItem(sort: { fields: [publishDate], order: ASC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        body {
          raw
        }
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`
